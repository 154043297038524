import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import RollingTextList from './RollingTextList'
import ArrowBase from './Arrow'
import { screenSizes, gutter } from '../theme'
import Img from 'gatsby-image'

const Container = styled.div`
  min-height: calc(100vh);
`

const GridContainer = styled.div`
  display: grid;
  flex-direction: column;
  grid-template-rows: 1fr;
  grid-row-gap: 30px;
  min-height: calc(100vh);
  width: 100%;
  align-items: center;

  padding: 150px ${gutter.mobile}px 0px calc(${gutter.mobile}px);

  @media (min-width: ${screenSizes.tablet}px) {
    padding: 210px ${gutter.tablet}px 0px calc(${gutter.tablet}px + 100px);
  }

  @media (min-width: ${screenSizes.desktop}px) {
    padding: 0px 20vw 0px calc(${gutter.desktop}px + 200px);
    grid-row-gap: 0px;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    padding: 0px 20vw 0px calc(${gutter.desktopLarge}px + 200px);
  }

  @media all and (orientation: portrait) and (min-width: ${screenSizes.tablet}px) {
    display: flex;
    justify-content: center;
    padding-top: 0;
  }
`

// const Header = styled.div`
//   display: flex;
//   align-items: center;

//   p {
//     line-height: 15pt;
//     font-size: 7pt;
//   }
// `

const Body = styled.div`
  position: relative;
  z-index: 5;
  margin-top: 15px;
  @media all and (orientation: portrait) and (min-width: ${screenSizes.tablet}px) {
    margin-top: 30px;
  }
`

const Arrow = styled(ArrowBase)`
  position: relative;
  z-index: 2;
  margin-left: ${props => props.margin && '15px'};
`

const Text = styled.p`
  max-width: 500px;
  line-height: 1;
  font-size: 10pt;
  ${props => props.uppercase && `text-transform: uppercase;`}
`

const Blurb = styled(Text)`
  max-width: 550px;
  line-height: 1.2;
  font-weight: normal;
  font-family: 'TanMemories';
  font-size: 20pt;

  @media (min-width: ${screenSizes.desktop}px) {
    font-size: 40pt;
  }
`

const TitleContainer = styled.div`
  font-size: 7.2vw;
  line-height: 8.5vw;
  max-width: 100%;
  width: 100%;
  margin-bottom: 15px;
  margin-left: -0.4vw;
  /* text-transform: uppercase; */

  span {
    font-family: 'TanMemories';
  }

  @media all and (orientation: portrait) {
    font-size: 13.2vw;
    line-height: 14vw;
    margin-bottom: 10vh;
  }
`

const BackgroundImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  grid-area: none;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: center center;
`

const Footer = styled.div`
  display: flex;

  @media (min-width: ${screenSizes.desktop}px) {
    padding-top: 20px;
    &.extra-padding {
      padding-top: 10vh;
    }
  }
`

class Hero extends React.PureComponent {
  constructor(props) {
    super(props)

    this.animateBack = false
    this.bfx = 0.0002
    this.bfy = 0.0002
    this.frameId = null
    this.prevOffsetY = 0
  }

  render() {
    const {
      backgroundImage,
      // renderHead,
      titleList,
      title,
      arrowText,
      body,
      extraPadding,
      rollTitle,
    } = this.props

    return (
      <>
        <Container>
          <GridContainer>
            {/* <Header>{renderHead && renderHead()}</Header> */}
            <Body>
              <TitleContainer>
                {titleList && rollTitle ? (
                  <RollingTextList
                    ref={ref => (this.rollingText = ref)}
                    textList={titleList}
                  />
                ) : titleList ? (
                  titleList.map((title, index) => (
                    <React.Fragment key={`hero-title-${index}`}>
                      <span>{title}</span>
                      <br />
                    </React.Fragment>
                  ))
                ) : (
                  <span>{title}</span>
                )}
              </TitleContainer>

              {body && <Blurb>{body}</Blurb>}

              <Footer className={extraPadding && 'extra-padding'}>
                <Text>
                  {arrowText?.split(' ').map((text, index) => (
                    <React.Fragment key={`arrow-text-${index}`}>
                      <span>{text}</span>
                      <br />
                    </React.Fragment>
                  ))}
                </Text>
                <Arrow
                  onClick={() => {
                    window.scrollTo({
                      top: window.innerHeight,
                      behavior: 'smooth',
                    })
                  }}
                  margin={arrowText}
                />
              </Footer>
            </Body>
          </GridContainer>
          {backgroundImage && (
            <BackgroundImage fluid={backgroundImage.childImageSharp.fluid} />
          )}
        </Container>
      </>
    )
  }
}
Hero.propTypes = {
  title: PropTypes.string,
  body: PropTypes.any,
  arrowText: PropTypes.string,
  titleList: PropTypes.array,
  renderHead: PropTypes.func,
  backgroundImage: PropTypes.object,
  extraPadding: PropTypes.bool,
  rollTitle: PropTypes.bool,
}

export default Hero
