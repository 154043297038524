import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import { colors } from '../theme'

const StrokeAnimation = keyframes`
  0% {
    stroke-dashoffset: 30;
  }

  10% {
    stroke-dashoffset: 10;
  }

  20% {
    stroke-dashoffset: 10;
  }

  40% {
    stroke-dashoffset: 0;
  }

  90% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -30;
  }
`

const TranslateAnimation = keyframes`
  0% {
    transform: translate3d(0, 0px, 0);
  }

  10% {
    transform: translate3d(0, 0px, 0);
  }

  20% {
    transform: translate3d(0, 10px, 0);
  }

  70% {
    transform: translate3d(0, 10px, 0);
  }

  100% {
    transform: translate3d(0, 10px, 0);
  }
`

const ArrowContainer = styled.span``

const ArrowPath = styled.path`
  stroke: ${colors.primaryText};
  fill: none;
  stroke-width: 1;
  stroke-dasharray: 30;
  stroke-dashoffset: 30;
  animation: ${StrokeAnimation} 3s linear infinite;
`

const ArrowSVG = styled.svg`
  transform: translate3d(0, 0, 0);
  animation: ${TranslateAnimation} 3s linear infinite;
`

const Arrow = ({ className, onClick }) => (
  <ArrowContainer onClick={onClick}>
    <ArrowSVG className={className} viewBox="0 0 10 20" height="30" width="20">
      <ArrowPath d="M1 0 L1 19 L 5 15" />
    </ArrowSVG>
  </ArrowContainer>
)
Arrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Arrow
